<template>
	<section id="carousel_produtos" :class="[{ 'dark-theme-produtos-sugeridos': temaEscuro }, { 'light-theme-produtos-sugeridos': !temaEscuro }]">
		<div class="container-produtos-sugeridos">
			<div class="container-titulo" v-show="exibirTitulo">
				<h2 class="font-32 head-bold dynamic-my-28 color-grey-3c">{{ title }}</h2>
			</div>

			<div v-if="viewCarregando">
				<div v-if="$mq.match(/lg/)">
					<component-carousel-base :cor-icone="listaProdutos.length < 12 ? '#fff' : '#ccc'" :exibir-paginacao="false" size-icone="30px">
						<slide class="container-itens">
							<ItemProduto produtos-sugeridos :config="itemProdutoConfig" :dados="item" :position="index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(0, 4)" :geolocation="geolocation"></ItemProduto>
						</slide>
						<slide class="container-itens" v-if="listaProdutos.length > 4">
							<ItemProduto produtos-sugeridos :config="itemProdutoConfig" :dados="item" :position="4 + index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(4, 8)" :geolocation="geolocation"></ItemProduto>
						</slide>
						<slide class="container-itens" v-if="listaProdutos.length > 8">
							<ItemProduto produtos-sugeridos :config="itemProdutoConfig" :dados="item" :position="8 + index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(8, 12)" :geolocation="geolocation"></ItemProduto>
						</slide>
					</component-carousel-base>
				</div>
				<div v-if="$mq.match(/sm|md/)">
					<component-carousel-base cor-icone="#ccc" size-icone="30px" prev-button="left: 20px" next-button="right: 30px">
						<slide class="container-itens">
							<ItemProduto produtos-sugeridos class="hidden-xs-down" :config="itemProdutoConfig" :dados="item" :position="index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(0, 2)" :geolocation="geolocation"></ItemProduto>
						</slide>
						<slide class="container-itens" v-if="listaProdutos.length > 3">
							<ItemProduto produtos-sugeridos class="hidden-xs-down" :config="itemProdutoConfig" :dados="item" :position="2 + index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(2, 4)" :geolocation="geolocation"></ItemProduto>
						</slide>
						<slide class="container-itens" v-if="listaProdutos.length > 5">
							<ItemProduto produtos-sugeridos class="hidden-xs-down" :config="itemProdutoConfig" :dados="item" :position="4 + index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(4, 6)" :geolocation="geolocation"></ItemProduto>
						</slide>
						<slide class="container-itens" v-if="listaProdutos.length > 7">
							<ItemProduto produtos-sugeridos class="hidden-xs-down" :config="itemProdutoConfig" :dados="item" :position="6 + index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(6, 8)" :geolocation="geolocation"></ItemProduto>
						</slide>
						<slide class="container-itens" v-if="listaProdutos.length > 9">
							<ItemProduto produtos-sugeridos class="hidden-xs-down" :config="itemProdutoConfig" :dados="item" :position="8 + index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(8, 10)" :geolocation="geolocation"></ItemProduto>
						</slide>
						<slide class="container-itens" v-if="listaProdutos.length > 11">
							<ItemProduto produtos-sugeridos class="hidden-xs-down" :config="itemProdutoConfig" :dados="item" :position="10 + index + 1" :gtm-config="gtmConfig" :key="index" v-for="(item, index) in listaProdutos.slice(10, 12)" :geolocation="geolocation"></ItemProduto>
						</slide>
					</component-carousel-base>
				</div>
				<div v-if="$mq.match(/xs/)">
					<component-carousel-base :exibir-paginacao="false" cor-icone="#ccc" size-icone="30px" prev-button="left: 20px" next-button="right: 30px">
						<slide class="container-itens" :key="index" v-for="(item, index) in listaProdutos">
							<ItemProduto produtos-sugeridos class="hidden-xs-down" :config="itemProdutoConfig" :dados="item" :position="index + 1" :gtm-config="gtmConfig" :geolocation="geolocation"></ItemProduto>
						</slide>
					</component-carousel-base>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ComponentCarouselBase from "@/components/Carousel/ComponentCarouselBase"
import ItemProduto from "@/components/ItemProduto.vue"
import ViewCarregando from "@/components/ViewCarregando.vue"
import lodash from "lodash"
import { checkProductAvailability, setAvailableStores } from "@/utils/availability"

export default {
	name: "ComponentCasouselProdutos",
	props: {
		gtmConfig: {
			type: Object,
		},
		temaEscuro: {
			type: Boolean,
			default: false,
		},
		listaProdutos: {
			type: Array,
			default: [],
		},
		title: {
			type: String,
			default: "Produtos",
		},
		exibirTitulo: {
			type: Boolean,
			default: true,
		},
		geolocation: {
			type: Object,
		},
	},
	components: {
		ComponentCarouselBase,
		ItemProduto,
		ViewCarregando,
	},
	data() {
		return {
			listaPreparada: [],
			isMobile: false,
			viewCarregando: true,
		}
	},
	computed: {
		features() {
			return this.$store.getters["featuresJson"]
		},
		itemProdutoConfig() {
			return {
				carregandoPrecoEstoque: this.viewCarregando,
			}
		},
		availableStores() {
			return this.$store.getters["availableStores"];
		},
	},
	async mounted() {
		// this.triggerEpaEvent()
		this.triggerGtmEvent()
	},
	methods: {
		checkPriority(product) {
			if (this.availableStores) {
				return checkProductAvailability(product, this.availableStores)
			} else {
				return checkProductAvailability(product, false)
			}
		},
		triggerEpaEvent() {
			if (this.listaProdutos.length > 0) {
				let products = this.listaProdutos.map((s) => ({
					item_id: s.id_produto_variacao,
					item_name: s.descricao,
					coupon: "",
					discount: 0,
					item_list_name: this.gtmConfig.listName,
					item_list_id: this.gtmConfig.listName,
					item_brand: "",
					item_category: "",
					item_variant: "",
					price: this.checkPriority(s).preco_desconto,
					currency: "BRL",
					quantity: 1,
				}))
			}
		},
		triggerGtmEvent() {
			this.$gtmHelpers.sendEvent.withProducts({
				event: "showProducts",
				products: {
					lists: {
						listaProdutos: this.listaProdutos,
					},
				},
				pagePath: this.gtmConfig.pagePath,
				pageType: this.gtmConfig.pageType,
				listName: this.gtmConfig.listName,
			})
		},
	},
}
</script>
<style lang="scss">
.dark-theme-produtos-sugeridos {
	background: #f0f0f0;
	.container-produtos-sugeridos {
		.component-item-produto {
			.container-foto > img {
				width: 200px;
				height: 200px;
			}
			.container-infos {
				background-color: #fff;
			}
			.botao-avise-me .container-label {
				margin: 0 auto;
			}
			.container-icone-bell {
				display: none;
			}
		}
	}
}
.light-theme-produtos-sugeridos {
	background: #fff;
	.container-produtos-sugeridos {
		.component-item-produto {
			.container-foto > img {
				width: 200px;
				height: 200px;
			}
			.container-infos {
				background-color: #fff;
			}
			.botao-avise-me .container-label {
				margin: 0 auto;
			}
			.container-icone-bell {
				display: none;
			}
		}
	}
}
.exibir-desktop {
	display: none;
	@media screen and(min-width:993px) {
		display: block;
	}
}
.exibir-tablet {
	display: none;
	@media screen and(min-width: 576px) and (max-width: 992px) {
		display: block;
	}
}
.exibir-celular {
	@media screen and(min-width: 576px) {
		display: none;
	}
}
</style>
<style lang="scss" scoped>
section {
	padding-top: 1px;
	.title {
		padding-left: 10px;
		width: calc(100% - 10px);
	}
}
#carousel_produtos {
	.container-titulo {
		display: flex;
		align-items: center;
	}
}

.container-produtos-sugeridos {
	max-width: 1220px;
	h2 {
		display: flex;
		align-items: center;
	}

	h2 img {
		height: 1.25em;
		margin-right: 0.5em;
	}
}

.container-produtos-sugeridos {
	.container-itens {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		.component-item-produto {
			max-width: 220px;
		}
	}
}
@media (max-width: 767px) {
	.container-produtos-sugeridos {
		.container-itens {
			.component-item-produto {
				max-width: 300px;
			}
		}
	}
}

.carousel-product {
	border-top: 0;
	.container-produtos-sugeridos {
		h2 {
			font-size: 32px;
			line-height: 39px;
			margin: 0;
			margin-bottom: 32px;
		}
	}
}
</style>
