var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.searchClient)?_c('ais-instant-search',{staticClass:"auto-suggest",attrs:{"search-client":_vm.searchClient,"index-name":"prod_main_products_query_suggestions"}},[_c('ais-configure',{attrs:{"hitsPerPage":5,"analyticsTags":_vm.ruleContext,"ruleContexts":_vm.ruleContext}}),_vm._v(" "),_c('ais-index',{attrs:{"index-name":_vm.ALGOLIA_INDEX_PRODUCTS}}),_vm._v(" "),_c('ais-autocomplete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var indices = ref.indices;
var refine = ref.refine;
return _c('div',{staticClass:"w-full"},[_c('vue-autosuggest',{staticClass:"autosuggest",attrs:{"suggestions":_vm.indicesToSuggestions(indices),"get-suggestion-value":_vm.getSuggestionValue,"should-render-suggestions":_vm.shouldRenderSuggestions,"input-props":{
					id: 'autosuggest__input',
					class: 'autosuggest__input inputsearch w-full',
					placeholder: 'Busque pelo nome ou código original da peça',
				}},on:{"selected":_vm.onSelected,"input":function($event){return _vm.tryRefine(refine)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var suggestion = ref.suggestion;
return [(suggestion.name === _vm.ALGOLIA_INDEX_PRODUCTS)?_c('div',{staticClass:"autosuggest__item font-16 head-regular mb-2",staticStyle:{"cursor":"pointer"}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(suggestion.item.descricao)+"\n\t\t\t\t\t\t"+_vm._s(suggestion.item.codigo_original)+"\n\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(suggestion.name === (_vm.ALGOLIA_INDEX_PRODUCTS + "_query_suggestions"))?_c('div',{staticClass:"autosuggest__item font-16 head-regular mb-2",staticStyle:{"cursor":"pointer"}},[_c('img',{staticClass:"mr-2",staticStyle:{"height":"16px"},attrs:{"src":"/custom/loja/assets/media/icons/lupa-primaria.svg"}}),_c('ais-highlight',{attrs:{"attribute":"query","hit":suggestion.item}})],1):_vm._e()]}}],null,true),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('template',{slot:"before-section-prod_main_products_query_suggestions"},[_c('div',{staticClass:"products"},[(_vm.querySize > 0 && !_vm.queryLoading)?_c('div',{staticClass:"products__title"},[_c('h3',{staticClass:"font-16 head-bold mb-2"},[_vm._v("Termos mais buscados")])]):_c('div',[_c('span',{staticClass:"head-regular",staticStyle:{"font-size":"14px"}},[_vm._v("Nenhum termo encontrado")])])])]),_vm._v(" "),_c('template',{slot:"before-section-prod_main_products_v2"},[_c('div',{staticClass:"products"},[_c('hr'),_vm._v(" "),(_vm.querySize > 0 && !_vm.queryLoading)?_c('div',{staticClass:"products__title"},[_c('h3',{staticClass:"font-16 head-bold mb-2"},[_vm._v("Produtos")])]):_c('div',[_c('span',{staticClass:"head-regular",staticStyle:{"font-size":"14px"}},[_vm._v("Nenhum produto encontrado")])])])])],2)],1)}}],null,false,931376987)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }