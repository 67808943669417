<template>
	<div>
		<!-- <ais-instant-search :search-client="searchClient" :index-name="algoliaIndex">
			<ais-configure :hitsPerPage="99"></ais-configure> -->
			<div class="filter__wrapper">
				<div>
					<h1 class="mb-4 md:mb-8" v-html="title"></h1>

					<div class="filter-3-cols">
						<div class="col-busca">
							<div class="searchbox w-full">
								<AutoCompleteSearchVW :searchClient="searchClient" ALGOLIA_INDEX_PRODUCTS="prod_main_products_eletrofrio" @newSearch="newSearch" />

								<div class="hidden lg1220:block" style="min-width: 180px">
									<button class="btn__primary mb-4" @click="tryRefine(query)">
										<img :class="clienteClass" :src="`/custom/loja/assets/media/icons/lupa-white.svg`" />
										Buscar
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="lg:hidden mx-auto">
						<button class="btn__primary mb-4" @click="tryRefine(query)">
							<img :class="clienteClass" :src="`/custom/loja/assets/media/icons/lupa-white.svg`" />
							Buscar
						</button>
					</div>
				</div>
			</div>
		<!-- </ais-instant-search> -->
	</div>
</template>

<script>
import { AisInstantSearch, AisConfigure, AisHits } from "vue-instantsearch"
import AutoCompleteSearchVW from "@/components/Common/AutoCompleteSearchVW"
import algoliasearch from "algoliasearch/lite"
export default {
	components: {
		AisInstantSearch,
		AisHits,
		AisConfigure,
		AutoCompleteSearchVW,
	},
	computed: {
		isVehicleSelected() {
			if (Object.keys(this.selected).length === 0) {
				return false
			}
			return true
		},
		categories() {
			return this.$epecaHubB2c.configJson.HOME_FILTER_CATEGORIES
		},
		title() {
			return this.$epecaHubB2c.configJson.HOME_FILTER_TITLE
		},
		algoliaIndex() {
			return "home_aplicacoes"
		},
		getFilterText() {
			if (!this.isVehicleSelected || !this.anoVeiculo) {
				return "Selecione <b style='color:#3C484D;font-weight:400;'>seu veículo</b> abaixo para encontrar <b style='color:#3C484D;font-weight:400;'>peças compatíveis</b>."
			} else {
				return "Você está pesquisando peças para:"
			}
		},
		cliente() {
			return process.env.envName
		},
		clienteClass() {
			return "default"
		},
		rotaListaPecas() {
			return "pecas-vw"
		},
	},
	methods: {
		newSearch(query) {
			this.query = query
			this.tryRefine(query)
		},
		tryRefine(query) {
			let trimQuery = query.trim()
			this.$store.dispatch("setLastSearch", trimQuery)
			this.$router.push({ path: `${this.createVehicleUrl()}`, query: { query: trimQuery } })
		},
		createCategoryUrl(category) {
			let veiculoQueryString = ""
			if (this.isVehicleSelected) {
				let veiculoString = this.selected.veiculo_base.toLowerCase().replace("!", "")
				veiculoQueryString = `?veiculo=${veiculoString}`
			}

			let veiculoAnoQueryString = ""
			if (this.anoVeiculo) {
				let anoString = this.anoVeiculo
				veiculoAnoQueryString = `&ano=${anoString}`
			}

			return `/lista/${this.rotaListaPecas}/${category}${veiculoQueryString}${veiculoAnoQueryString}`
		},
		createVehicleUrl() {
			let veiculoAnoQueryString = ""

			if (this.anoVeiculo) {
				let anoString = this.anoVeiculo
				veiculoAnoQueryString = `&ano=${anoString}`
			}

			if (this.isVehicleSelected) {
				let veiculoString = this.selected.veiculo_base.toLowerCase().replace("!", "")
				return `/lista/?veiculo=${veiculoString}${veiculoAnoQueryString}`
			}
			return `/lista/`
		},
		clearFilter() {
			this.veiculoSelecionado = ""
			this.veiculoAnoSelecionado = ""
			this.selected = {}
			this.anoVeiculo = null
			window.scrollTo({ top: 0, behavior: "smooth" })
		},
	},
	mounted() {
		this.$store.dispatch("setLastSearch", this.query)
	},
	data() {
		return {
			searchClient: algoliasearch(this.$epecaHubB2c.configJson.ALGOLIA_APPLICATION_ID, this.$epecaHubB2c.configJson.ALGOLIA_API_KEY_READ_ONLY),
			veiculoSelecionado: "",
			veiculoAnoSelecionado: "",
			selected: {},
			anoVeiculo: null,
			query: "",
		}
	},
}
</script>

<style scoped lang="scss">
.filter__wrapper {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
	flex: none;
	order: 1;
	flex-grow: 0;
	padding: 16px 24px;
	display: flex;
	gap: 32px;
	display: grid;
	flex-direction: column;
	@media (min-width: 1220px) {
		background: #ffffff;
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
		flex: none;
		order: 1;
		flex-grow: 0;
		padding: 24px;
		display: flex;
		height: inherit;
	}
}
h1 {
	color: #6a767d;
	text-align: center;
	font-size: 24px;
	line-height: 29px;
	b {
		color: #3c484d;
	}
	@media (min-width: 1024px) {
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 16px;
	}
}
.filter-title {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	align-items: center;
	color: #6a767d;
	font-family: var(--head-regular);
	display: inline-block;
	margin-bottom: 8px;
	b {
		font-weight: 400;
		color: #3c484d;
	}
	@media (min-width: 1220px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.filter-widgets {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}
.categories-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 8px;
	@media (min-width: 1220px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 4px;
	}
}
.category-btn {
	background: #ffffff;
	display: flex;
	text-align: center;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: #3c484d;
	gap: 8px;
	padding: 8px;
	width: 100%;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	font-family: var(--head-bold);
	cursor: pointer;
	img {
		height: 24px;
		width: 24px;
	}
	img.default {
		filter: invert(98%) sepia(1%) saturate(7230%) hue-rotate(182deg) brightness(94%) contrast(72%);
	}
	&:hover {
		color: #001e50;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
		img.default {
			filter: invert(10%) sepia(33%) saturate(4855%) hue-rotate(207deg) brightness(92%) contrast(104%);
		}
		svg {
			path {
				fill: #001e50;
			}
		}
	}
	@media (min-width: 1220px) {
		background: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 11px;
		line-height: 13px;
		color: #3c484d;
		gap: 8px;
		padding: 8px;
		width: 75px;
		height: 56px;
		cursor: pointer;
		box-shadow: none;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
		font-family: var(--text-regular);
		font-weight: 400;
		&:hover {
			color: #001e50;

			svg {
				path {
					fill: #001e50;
				}
			}
		}
	}
}
.filter-image {
	max-width: 330px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 1220px) {
		max-width: 100%;
	}
	img {
		max-width: 100%;
	}
}
.carro__img {
	cursor: pointer;
	margin: 0 auto;
	@media (min-width: 1220px) {
		img {
			max-width: 156px;
			max-height: 88px;
		}
	}
	&:hover {
		outline: 2px solid #001e50;
	}
}
.select-vw__wrapper {
	max-width: 100%;
	&:last-of-type {
		.select-vw__label {
			margin-bottom: 0;
		}
	}
}
.select-vw__label {
	position: relative;
	display: block;
	color: rgb(0, 30, 80);
	cursor: pointer;
}
.select-vw__select {
	appearance: none;
	height: 42px;
	width: 100%;
	padding: 8px 32px 8px 0px;
	border: 0px;
	border-radius: 0px;
	border-bottom: 2px solid var(--primary_color);
	color: inherit;
	background: transparent;
	font-size: 16px;
	font-family: var(--text-regular);
	outline: none;
	cursor: pointer;
}
.select-vw__empty {
	font-size: 12px;
	width: 100%;
	display: flex;
	transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	pointer-events: none;
	margin: 0px;
	padding: 0px;
	position: absolute;
	top: 0px;
}
.select-vw__iconwrapper {
	position: absolute;
	bottom: 8px;
	display: flex;
	width: 100%;
	-webkit-box-pack: end;
	justify-content: flex-end;
	height: 24px;
	pointer-events: none;
	transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	-webkit-box-align: center;
	align-items: center;
	white-space: nowrap;
}
.select-vw__icon {
	position: relative;
	flex: 0 0 auto;
	overflow: visible;
	text-align: center;
	transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	line-height: 0;
}
.select-vw__icon::before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	padding: 12px;
	border-radius: 50%;
}
.select-vw__label:focus-within .StyledIconWrapper-sc-jne68a::before {
	background-color: rgb(0, 119, 204);
	content: "";
	position: absolute;
	width: 42px;
	height: 42px;
	border-radius: 999px;
	transform: translateX(9px);
	box-shadow: rgb(0, 30, 80) 0px 0px 0px 3px inset;
}
.select-vw__label:focus-within .StyledIconWrapper-sc-jne68a {
	color: rgb(255, 255, 255);
}
.cZqTKB {
	display: flex;
}
.filter-veiculo {
	font-size: 20px;
	line-height: 24px;
	color: #3c484d;
}
.searchbox {
	display: flex;
	border-bottom: 2px solid var(--primary_color);
	align-items: center;
}
.inputsearch {
	border: none;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	color: #3c484d;
	font-family: var(--head-regular);

	height: 53px;
	position: relative;
	&::placeholder {
		font-weight: 300;
		font-size: 16px;
		line-height: 20px;
		color: #3c484d;
		font-family: var(--head-regular);
	}
	@media (max-width: 767px) {
		font-size: 14px;
		line-height: 17px;
		&::placeholder {
			font-size: 14px;
			line-height: 17px;
		}
	}
}
.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;

	padding: 6px;
	color: #fff;
	letter-spacing: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	display: inline-block;
	border-radius: 4px;
	background-color: var(--tooltip-bg-color);
	transition: opacity 300ms ease 0s;
	animation-duration: 0.4s;
	@media (max-width: 767px) {
		font-size: 14px;
		width: 92px;
	}
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
.filter-3-cols {
	display: flex;
	flex-direction: column;
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: 1fr;
		gap: 64px;
	}
}
.col-veiculos {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-bottom: 16px;
	&:after {
		@media (min-width: 1220px) {
			content: "";
			display: block;
			background: #96a3a8;
			width: 2px;
			height: 100%;
			position: absolute;
			right: -32px;
			top: 0;
		}
	}
	@media (min-width: 1220px) {
		margin-bottom: 0;
	}
}
.col-busca {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	margin-bottom: 16px;
	&:after {
		// @media (min-width: 1220px) {
		// 	content: "";
		// 	display: block;
		// 	background: #96a3a8;
		// 	width: 2px;
		// 	height: 100%;
		// 	position: absolute;
		// 	right: -32px;
		// 	top: 0;
		// }
	}
	@media (min-width: 1220px) {
		margin-bottom: 0;
		flex-direction: row;
	}
}
.col-categoria {
	margin-bottom: 16px;
	@media (min-width: 1220px) {
		margin-bottom: 0;
	}
}
.carro__wrapper {
	width: 72px;
	margin-right: 32px;
}
.veiculo-selecionado-box {
	@media (min-width: 1220px) {
		display: flex;
		gap: 16px;
	}
}
.veiculo-selecionado-text {
	@media (min-width: 1220px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
.informacoes-carro {
	display: flex;
	flex-direction: column;
	@media (min-width: 1220px) {
		display: grid;
	}
}
</style>
