<template>
	<section id="view-home">
		<div class="glide carousel-home-desktop hidden xs:block mb-4" v-if="possuiBanners && banners.HOME_BANNERS">
			<div data-glide-el="track" class="glide__track">
				<ul class="glide__slides">
					<li class="glide__slide" v-for="b in banners.HOME_BANNERS" :key="b.id">
						<router-link :to="b.url"><img :src="b.src" /></router-link>
					</li>
				</ul>
				<div class="glide__bullets" data-glide-el="controls[nav]" v-if="banners.HOME_BANNERS.length > 1">
					<button v-for="(d, $d) in banners.HOME_BANNERS" :key="$d" class="glide__bullet" :data-glide-dir="`=${$d}`"></button>
				</div>
				<div class="glide__arrows" data-glide-el="controls" v-if="banners.HOME_BANNERS.length > 1">
					<button class="glide__arrow glide__arrow--left" data-glide-dir="<"><i class="fas fa-chevron-left"></i></button>
					<button class="glide__arrow glide__arrow--right" data-glide-dir=">"><i class="fas fa-chevron-right"></i></button>
				</div>
			</div>
		</div>

		<div class="glide carousel-home-mobile xs:hidden mb-4" v-if="possuiBanners && banners.HOME_BANNERS_MOBILE">
			<div data-glide-el="track" class="glide__track">
				<ul class="glide__slides">
					<li class="glide__slide" v-for="b in banners.HOME_BANNERS_MOBILE" :key="b.id">
						<router-link :to="b.url"><img :src="b.src" /></router-link>
					</li>
				</ul>
				<div class="glide__bullets" data-glide-el="controls[nav]" v-if="banners.HOME_BANNERS_MOBILE.length > 1">
					<button v-for="(d, $d) in banners.HOME_BANNERS_MOBILE" :key="$d" class="glide__bullet" :data-glide-dir="`=${$d}`"></button>
				</div>
				<div class="glide__arrows" data-glide-el="controls" v-if="banners.HOME_BANNERS_MOBILE.length > 1">
					<button class="glide__arrow glide__arrow--left" data-glide-dir="<"><i class="fas fa-chevron-left"></i></button>
					<button class="glide__arrow glide__arrow--right" data-glide-dir=">"><i class="fas fa-chevron-right"></i></button>
				</div>
			</div>
		</div>

		<div class="section mb-8">
			<HomeFilter />
		</div>

		<Divisor />

		<client-only>
			<div v-if="loadingFeatured">
				<CarouselSkeleton />
			</div>
		</client-only>

		<div class="section" v-if="exibirProdutosDestaque">
			<ComponentCasouselProdutos
				:gtm-config="{
					pagePath: '/',
					pageType: 'home',
					listName: 'home:produtos-em-destaque',
				}"
				:lista-produtos="listaProdutosDestaque"
				title="Produtos em destaque"
				:geolocation="geolocation"
				v-if="!loadingFeatured && listaProdutosDestaque.length > 0"
			/>
		</div>

		<Divisor style="margin-top: 44px; margin-bottom: 44px" v-if="bannersMarketingTop" />

		<div
			v-if="bannersMarketingTop"
			class="cursor-pointer banner__marketing--top"
			:style="{
				'background-image': `url(${bannersMarketingTop.src})`,
			}"
			@click="bannerTopClick(bannersMarketingTop.url)"
		></div>

		<Divisor style="margin-top: 44px" />
		<!-- Categories -->
		<div class="section" style="margin-bottom: 44px">
			<h2 class="font-32 head-bold dynamic-my-28 text-center">
				{{ $epecaHubB2c.configJson.HOME_PARTS_TITLE_TEXT }}
			</h2>

			<div class="container-pecas-e-acessorios">
				<div class="container-4-itens">
					<component
						class="container-item"
						gtm-action="click"
						:gtm-value="'categorias:' + $helpers.criarUrlAmigavel($epecaHubB2c.configJson.HOME_PARTS_BUTTON_01_LABEL)"
						:is="'nuxt-link'"
						:to="`${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_01_LINK_URL_INTERNAL}`"
						@click.native="triggerCategoriaClick($epecaHubB2c.configJson.HOME_PARTS_BUTTON_01_LINK_URL_INTERNAL)"
					>
						<div class="container-foto">
							<div
								class="container-imagem-bg"
								:style="{
									'background-image': `url(${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_01_BACKGROUND_IMAGE_URL})`,
								}"
							></div>
						</div>

						<h3 style="opacity: 0; height: 0">{{ $epecaHubB2c.configJson.HOME_PARTS_BUTTON_01_LABEL }}</h3>
					</component>

					<component
						class="container-item"
						gtm-action="click"
						:gtm-value="'categorias:' + $helpers.criarUrlAmigavel($epecaHubB2c.configJson.HOME_PARTS_BUTTON_02_LABEL)"
						:is="'nuxt-link'"
						:to="`${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_02_LINK_URL_INTERNAL}`"
						@click.native="triggerCategoriaClick($epecaHubB2c.configJson.HOME_PARTS_BUTTON_02_LINK_URL_INTERNAL)"
					>
						<div class="container-foto">
							<div
								class="container-imagem-bg"
								:style="{
									'background-image': `url(${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_02_BACKGROUND_IMAGE_URL})`,
								}"
							></div>
						</div>
						<h3 style="opacity: 0; height: 0">{{ $epecaHubB2c.configJson.HOME_PARTS_BUTTON_02_LABEL }}</h3>
					</component>

					<component
						class="container-item"
						gtm-action="click"
						:gtm-value="'categorias:' + $helpers.criarUrlAmigavel($epecaHubB2c.configJson.HOME_PARTS_BUTTON_03_LABEL)"
						:is="'nuxt-link'"
						:to="`${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_03_LINK_URL_INTERNAL}`"
						@click.native="triggerCategoriaClick($epecaHubB2c.configJson.HOME_PARTS_BUTTON_03_LINK_URL_INTERNAL)"
					>
						<div class="container-foto">
							<div
								class="container-imagem-bg"
								:style="{
									'background-image': `url(${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_03_BACKGROUND_IMAGE_URL})`,
								}"
							></div>
						</div>
						<h3 style="opacity: 0; height: 0">{{ $epecaHubB2c.configJson.HOME_PARTS_BUTTON_03_LABEL }}</h3>
					</component>

					<component
						class="container-item"
						gtm-action="click"
						:gtm-value="'categorias:' + $helpers.criarUrlAmigavel($epecaHubB2c.configJson.HOME_PARTS_BUTTON_04_LABEL)"
						:is="'nuxt-link'"
						:to="`${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_04_LINK_URL_INTERNAL}`"
						@click.native="triggerCategoriaClick($epecaHubB2c.configJson.HOME_PARTS_BUTTON_04_LINK_URL_INTERNAL)"
					>
						<div class="container-foto">
							<div
								class="container-imagem-bg"
								:style="{
									'background-image': `url(${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_04_BACKGROUND_IMAGE_URL})`,
								}"
							></div>
						</div>
						<h3 style="opacity: 0; height: 0">{{ $epecaHubB2c.configJson.HOME_PARTS_BUTTON_04_LABEL }}</h3>
					</component>
				</div>

				<div class="container-1-item">
					<component
						class="container-item"
						gtm-action="click"
						:gtm-value="'categorias:' + $helpers.criarUrlAmigavel($epecaHubB2c.configJson.HOME_PARTS_BUTTON_05_INNER_LABEL)"
						:is="'nuxt-link'"
						:to="`${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_05_LINK_URL_INTERNAL}`"
						@click.native="triggerCategoriaClick($epecaHubB2c.configJson.HOME_PARTS_BUTTON_05_LINK_URL_INTERNAL)"
					>
						<div class="container-foto">
							<div
								class="container-imagem-bg"
								:style="{
									'background-image': `url(${$epecaHubB2c.configJson.HOME_PARTS_BUTTON_05_BACKGROUND_IMAGE_URL})`,
								}"
							></div>
						</div>
						<h3 style="opacity: 0; height: 0">{{ $epecaHubB2c.configJson.HOME_PARTS_BUTTON_05_LABEL }}</h3>
					</component>
				</div>
			</div>
		</div>

		<Divisor />

		<!-- Categoria 2 -->
		<client-only>
			<div v-if="loadingCategories">
				<CarouselSkeleton />
			</div>
		</client-only>

		<div class="section" style="margin-bottom: 44px">
			<ComponentCasouselProdutos
				:gtm-config="{
					pagePath: '/',
					pageType: 'home',
					listName: `home:${$helpers.criarUrlAmigavel(titleProdutosCategoria)}`,
				}"
				:lista-produtos="listaProdutosCategoria"
				:title="titleProdutosCategoria"
				:geolocation="geolocation"
				v-if="!loadingCategories && listaProdutosCategoria.length > 0"
			/>
		</div>

		<Divisor style="margin-top: 44px; margin-bottom: 44px" v-if="bannersMarketingBottom" />

		<div class="grid-2-col" v-if="bannersMarketingBottom">
			<div
				v-for="(item, $i) in bannersMarketingBottom"
				:key="$i"
				class="cursor-pointer banner__marketing--bottom"
				@click="bannerBottomClick(item.url)"
				:style="{
					'background-image': `url(${item.src})`,
				}"
			></div>
		</div>
	</section>
</template>

<script>
import Glide, { Controls, Breakpoints, Swipe } from "@glidejs/glide/dist/glide.modular.esm"
import { setAvailableStores } from "@/utils/availability"
import { mapGetters } from "vuex"
import HomeFilter from "~/components/Common/HomeFilter"
import ComponentCasouselProdutos from "~/components/ComponentCasouselProdutos"
import CarouselSkeleton from "@/components/Common/CarouselSkeleton"
import algoliasearch from "algoliasearch/lite"

async function carregarProdutoPorId(productIds, indexProdutos, defaultStoreId) {
	let ret = {}

	let arrStringFilters = []
	let stringFilters = `({FILTER_ITEMS})`

	productIds.forEach((item) => {
		arrStringFilters.push(`id_produto=${item} OR `)
	})

	if (arrStringFilters.length > 0) {
		arrStringFilters[arrStringFilters.length - 1] = arrStringFilters[arrStringFilters.length - 1].replace(" OR ", "")

		stringFilters = stringFilters.replace("{FILTER_ITEMS}", arrStringFilters.join(""))

		ret = await indexProdutos.search(
			"",
			{
				filters: stringFilters,
				optionalFilters: `${setAvailableStores(defaultStoreId)}`,
			},
			{}
		)
	}

	return ret
}

export default {
	props: [],
	components: {
		HomeFilter,
		ComponentCasouselProdutos,
		CarouselSkeleton,
	},
	head() {
		const itemMarketingSelecionado = this.itemMarketingSelecionado

		let head = {}
		let description = "Produtos exclusivos e diversas categorias para você encontrar o que precisa. Pague online e receba com segurança."
		let ogTitle = null
		let ogDescription = null
		let title = "Página inicial"

		head.meta = []

		if (itemMarketingSelecionado) {
			if (itemMarketingSelecionado.meta) {
				title = itemMarketingSelecionado.meta.title
				description = itemMarketingSelecionado.meta.description
			}

			if (itemMarketingSelecionado.og) {
				ogTitle = itemMarketingSelecionado.og.title
				ogDescription = itemMarketingSelecionado.og.description
			}
		}

		head.title = title

		head.meta.push(
			{
				hid: "description",
				name: "description",
				content: description,
			},
			{
				property: "og:title",
				content: ogTitle || title,
			},
			{
				property: "og:description",
				content: ogDescription || description,
			}
		)

		head.meta.push(
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:image",
				content: process.env.baseUrl + "/custom/loja/assets/media/favicon.png",
			}
		)

		if (process.env.baseUrl) {
			let baseUrl = process.env.baseUrl
			let fullPath = this.$nuxt.$router.currentRoute.fullPath

			let link = [
				{ rel: "canonical", href: `${baseUrl}${fullPath}` },
				{ rel: "alternate", href: `${baseUrl}${fullPath}`, hreflang: "pt-br" },
			]
			head.link = link

			head.meta.push(
				{
					property: "og:url",
					content: `${baseUrl}${fullPath}`,
				},
				{
					property: "og:image",
					content: process.env.baseUrl + "/custom/loja/assets/media/favicon.png",
				}
			)
		}

		return head
	},
	async asyncData({ route, app }) {
		const algoliaClient = algoliasearch(app.$epecaHubB2c.configJson.ALGOLIA_APPLICATION_ID, app.$epecaHubB2c.configJson.ALGOLIA_API_KEY_READ_ONLY)

		const searchClient = {
			...algoliaClient,
			search(requests) {
				const newRequests = requests.map((request) => {
					if (!request.params.query || request.params.query.length === 0) {
						request.params.analytics = false
					}
					return request
				})

				return algoliaClient.search(newRequests)
			},
		}

		const indexNameProdutos = process.env.ALGOLIA_INDEX_PRODUCTS
		const indexProdutos = searchClient.initIndex(indexNameProdutos)

		const marketingInfo = await app.$helpers.carregarMarketingInfo(app)
		const itemMarketingSelecionado = app.$helpers.retornarItemMarketingPelaUrl(app, route, marketingInfo)

		return {
			searchClient,
			indexNameProdutos,
			indexProdutos,
			itemMarketingSelecionado,
			marketingInfo,
		}
	},
	data() {
		return {
			geolocation: null,
			listaProdutosDestaque: [],
			listaProdutosCategoria: [],
			titleProdutosCategoria: [],
			baseUrl: "",
			loadingFeatured: false,
			loadingCategories: false,
		}
	},
	computed: {
		...mapGetters(["selecionouCidade", "availableStores", "cep", "featuredIds", "carouselCategory"]),
		configJson() {
			return this.$epecaHubB2c.configJson
		},
		defaultStoreId() {
			if (this.configJson.DEFAULT_STORE_ID) {
				return this.configJson.DEFAULT_STORE_ID.split(",")
			}
		},
		cliente() {
			return process.env.envName
		},
		bannersMarketingTop() {
			return this.$epecaHubB2c.configJson.BANNERS_MARKETING_TOP
		},
		bannersMarketingBottom() {
			return this.$epecaHubB2c.configJson.BANNERS_MARKETING_BOTTOM
		},
		exibirProdutosDestaque() {
			return this.listaProdutosDestaque.length > 0
		},
		banners() {
			return this.$store.state.bannerJson
		},

		featuresJson() {
			let ret = {}

			if (this.$epecaHubB2c) {
				ret = this.$epecaHubB2c.featuresJson
			}

			return ret
		},
		possuiBanners() {
			if (Object.keys(this.banners).length > 0) {
				return true
			}
			return false
		},
	},
	async beforeMount() {
		if (this.$epecaHubB2c.featuresJson.CAROUSEL_CATEGORIES) {
			this.carouselProdutosPorCategoria()
		}

		if (this.$epecaHubB2c.featuresJson.CAROUSEL_FEATURED) {
			this.loadingFeatured = true
			this.listaProdutosDestaque = await this.carouselProdutosDestaque()
			this.loadingFeatured = false
		}
	},
	mounted() {
		this.updateCepRules()
		if (this.banners.HOME_BANNERS) {
			this.$nextTick(() => {
				new Glide(".carousel-home-desktop", {
					type: "slider",
					perView: 1,
				}).mount({ Controls, Breakpoints, Swipe })
			})
		}
		if (this.banners.HOME_BANNERS_MOBILE) {
			this.$nextTick(() => {
				new Glide(".carousel-home-mobile", {
					type: "slider",
					perView: 1,
				}).mount({ Controls, Breakpoints, Swipe })
			})
		}
	},
	watch: {
		cep: {
			immediate: false,
			handler(value) {
				this.updateCepRules()
			},
		},
		selecionouCidade: {
			immediate: true,
			async handler(value) {
				if (process.client) {
					// Se tiver location selecionado, busca da store
					if (value) {
						if (this.$epecaHubB2c.featuresJson.CAROUSEL_CATEGORIES) {
							this.carouselProdutosPorCategoria()
						}

						if (this.$epecaHubB2c.featuresJson.CAROUSEL_FEATURED) {
							this.listaProdutosDestaque = await this.carouselProdutosDestaque()
						}
					} else {
						// Tem default store Id
						if (this.configJson.DEFAULT_STORE_ID) {
							let idsFromJson = this.configJson.DEFAULT_STORE_ID.split(",")
							this.$store.dispatch("setStoreIds", idsFromJson)
						}
					}
				}
			},
		},
	},
	methods: {
		triggerCategoriaClick(url) {
			if (process.env.TOKEN_EPA) {
				let $epa = new window._epa({
					cKey: process.env.TOKEN_EPA,
				})

				$epa.send("banner-categorias", {
					url: url,
				})
			}
		},
		bannerBottomClick(url) {
			if (process.env.TOKEN_EPA) {
				let $epa = new window._epa({
					cKey: process.env.TOKEN_EPA,
				})

				$epa.send("banner-rodape", {
					url: url,
				})
			}

			this.$router.push({ path: `${url}` })
		},
		bannerTopClick(url) {
			if (process.env.TOKEN_EPA) {
				let $epa = new window._epa({
					cKey: process.env.TOKEN_EPA,
				})

				$epa.send("banner-topo", {
					url: url,
				})
			}

			this.$router.push({ path: `${url}` })
		},
		updateCepRules() {
			this.geolocation = this.$storage.getUniversal("geolocation")
		},

		async carouselProdutosDestaque() {
			const searchClient = algoliasearch(this.$epecaHubB2c.configJson.ALGOLIA_APPLICATION_ID, this.$epecaHubB2c.configJson.ALGOLIA_API_KEY_READ_ONLY)
			const indexNameProdutos = process.env.ALGOLIA_INDEX_PRODUCTS
			const indexProdutos = searchClient.initIndex(indexNameProdutos)
			let carouselFeaturedPath = this.$epecaHubB2c.configJson.HOME_CAROUSEL_FEATURED

			await this.$store.dispatch("setFeaturedCarousel", carouselFeaturedPath)

			const produtoAlgolia = await carregarProdutoPorId(this.featuredIds, indexProdutos, this.availableStores)
			const listaProdutosDestaque = produtoAlgolia.hits

			return listaProdutosDestaque
		},

		async carouselProdutosPorCategoria() {
			this.loadingCategories = true
			const searchClient = algoliasearch(this.$epecaHubB2c.configJson.ALGOLIA_APPLICATION_ID, this.$epecaHubB2c.configJson.ALGOLIA_API_KEY_READ_ONLY)
			const indexNameProdutos = process.env.ALGOLIA_INDEX_PRODUCTS
			const indexProdutos = searchClient.initIndex(indexNameProdutos)

			let carouselCategoryPath = this.$epecaHubB2c.configJson.HOME_CAROUSEL_CATEGORIES

			await this.$store.dispatch("setProductByCategory", carouselCategoryPath)

			const produtoCategoriaAlgolia = await this.carregarProdutoPorCategoria(this.carouselCategory, indexProdutos, this.availableStores)
			const listaProdutoCategoria = produtoCategoriaAlgolia.hits
			this.listaProdutosCategoria = listaProdutoCategoria
			this.titleProdutosCategoria = this.carouselCategory.labelCarousel
			this.loadingCategories = false
		},
		async carregarProdutoPorCategoria(produtoCategoria, indexProdutos, defaultStoreId) {
			let ret = {}
			ret = await indexProdutos.search(
				"",
				{
					filters: `${produtoCategoria.filtro}:"${produtoCategoria.filtro_valor}"`,
					optionalFilters: `${setAvailableStores(defaultStoreId)}`,

					offset: 0,
					length: 12,
				},
				{}
			)
			return ret
		},
	},
}
</script>
<style lang="scss">
.glide__arrow {
	background-color: #fff;
	border: 1px solid #c2c2c2;
	i {
		color: #535353;
	}
	&--left {
		left: 16px;
	}
	&--right {
		right: 16px;
	}
}
</style>
<style lang="scss" scoped>
.container-item {
	color: #111;
	cursor: pointer;
	text-decoration: none;
}

.container-item .container-imagem-bg {
	background-size: cover;
	background-position: center;
}

.container-item .container-foto {
	line-height: 0;
	position: relative;
	height: 284px;
}
@media (max-width: 767px) {
	.container-item .container-foto {
		height: inherit;
	}
}
.container-pecas-e-acessorios .container-4-itens {
	display: flex;
	flex-wrap: wrap;
}

.container-pecas-e-acessorios .container-4-itens .container-item .container-imagem-bg {
	height: 286px;
	width: 286px;
}

.container-pecas-e-acessorios .container-1-item {
	flex: 1;
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.15s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

@media (max-width: 767px) {
	.container-item .container-info > div {
		font-size: 1.2em;
	}

	.container-pecas-e-acessorios .container-4-itens .container-item {
		width: 42vw;
	}

	.container-pecas-e-acessorios .container-4-itens {
		justify-content: space-between;
		margin-bottom: 16px;
		grid-gap: 16px;
	}

	.container-pecas-e-acessorios .container-4-itens .container-item .container-imagem-bg {
		height: 42vw;
		width: 100%;
	}

	.container-pecas-e-acessorios .container-1-item .container-item {
		width: 100%;
	}

	.container-pecas-e-acessorios .container-1-item .container-imagem-bg {
		height: 370px;
		width: 100%;
	}
}

/** Media do Ipad */
@media (min-width: 768px) and (max-width: 1199px) {
	.container-pecas-e-acessorios .container-1-item {
		text-align: center;
	}

	.container-pecas-e-acessorios .container-4-itens {
		justify-content: space-between;
		gap: 32px;
		margin-bottom: 32px;
	}

	.container-pecas-e-acessorios .container-4-itens .container-item .container-imagem-bg {
		height: inherit;
		width: 100%;
	}

	.container-pecas-e-acessorios .container-1-item .container-item {
		width: 100%;
	}

	.container-pecas-e-acessorios .container-1-item .container-imagem-bg {
		height: 40vw;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.container-pecas-e-acessorios {
		display: flex;
	}

	.container-pecas-e-acessorios .container-4-itens {
		margin-right: 26px;
		justify-content: space-between;
	}

	.container-pecas-e-acessorios .container-4-itens .container-item {
		width: 284px;
	}

	.container-pecas-e-acessorios .container-4-itens .container-item .container-imagem-bg {
		height: 284px;
		width: 284px;
	}

	.container-pecas-e-acessorios .container-1-item {
		text-align: right;
	}

	.container-pecas-e-acessorios .container-1-item .container-item {
		width: 597px;
	}

	.container-pecas-e-acessorios .container-1-item .container-imagem-bg {
		height: 596px;
		width: 597px;
	}
}

.container-4-itens {
	display: grid !important;
	grid-template-columns: 1fr 1fr;
	flex-wrap: wrap;
	gap: 28px;
}
.banner {
	&__marketing {
		&--top,
		&--bottom {
			background-size: cover;
			height: 150px;
		}
	}
}
@media (min-width: 574px) {
	.banner {
		&__marketing {
			&--top,
			&--bottom {
				height: 269px;
			}
		}
	}
}
@media (min-width: 768px) {
	.banner {
		&__marketing {
			&--top {
				height: 132px;
			}
			&--bottom {
				height: 398px;
			}
		}
	}
}
@media (min-width: 992px) {
	.banner {
		&__marketing {
			&--top {
				height: 171px;
			}
			&--bottom {
				height: 260px;
			}
		}
	}
}
@media (min-width: 1200px) {
	.banner {
		&__marketing {
			&--top {
				height: 380px;
			}
			&--bottom {
				height: 309px;
			}
		}
	}
}
@media (min-width: 1400px) {
	.banner {
		&__marketing {
			&--top {
				height: 380px;
			}
			&--bottom {
				height: 390px;
			}
		}
	}
}
.banner-mobile {
	display: block;
	@media (min-width: 400px) {
		display: none;
	}
}
.banner-desktop {
	display: none !important;
	@media (min-width: 400px) {
		display: block;
	}
}
@media (min-width: 401px) {
	.xs\:hidden {
		display: none;
	}
}
@media (min-width: 401px) {
	.xs\:block {
		display: block;
	}
}
.carousel-home-mobile {
	padding: 0 16px;
}
</style>
